.Education {
    height: 100%;
    padding: 30px 0px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.Title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    text-decoration: underline;
    color: #525252;
    margin-top: 0px;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .Education {
        padding: 45px 0px;
    }

    .EducationItems {
        display: block;
    }
}