.Skills {
    background-color: rgba(200, 116, 122, 0.1);
    font-family: 'Roboto', sans-serif;
    color: #525252;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 15px;
}

.Title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    text-decoration: underline;
    margin-bottom: 30px;
    margin-top: 0px;
}

.SkillsList {
    padding: 0 25px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .Skills {
        padding-top: 45px;
        padding-bottom: 30px;
    }
}