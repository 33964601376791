.Experience {
    height: 100%;
    background-color: rgba(200, 116, 122, 0.1);
    padding-top: 30px;
    padding-bottom: 60px;
}

.Title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    text-decoration: underline;
    margin-bottom: 30px;
    margin-top: 0px;
    color: #525252;
}

.ExperienceItems {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .Experience {
        padding-top: 45px;
    }
}