.ProjectItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

.Modal {
    font-family: 'Roboto', sans-serif;
    color: #525252;
}

.Name {
    text-align: center;
    margin-bottom: 2px;
    margin-top: 0;
}

.Date {
    text-align: center;
    font-size: 14px;
    margin-top: 0;
}

.InProgress {
    text-align: center;
    font-size: 14px;
    margin-top: 0;
    color: #C8747A;
    font-weight: bold;
}

.ButtonDiv {
    display: flex;
    justify-content: space-evenly;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.Button {
    background-color:white;
    color: #C8747A;
    border: 1px solid #C8747A;
    border-radius: 10%;
    cursor: pointer;
    height: 35px;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.ButtonText {
    padding-left: 5px;
    padding-right: 3px;
}

.Icon {
    align-self: start;
    font-size: 14px;
    padding-top: 2px;
    padding-right: 3px;
}

.Content {
    font-size: 15px;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .ProjectItems {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .ButtonDiv {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}