.FeaturedProjects {
    background-color: rgba(200, 116, 122, 0.1);
    height: 100%;
}

.Title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #525252;
    text-decoration: underline;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 30px;
}

.LearnMoreText {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 16px;
    color: #95585C;
    text-decoration: underline;
    padding-top: 30px;
    padding-bottom: 60px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
}

.RightArrowIcon {
    vertical-align: bottom;
    padding-bottom: 1px;
}

.ProjectItems {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Modal {
    font-family: 'Roboto', sans-serif;
    color: #525252;
}

.Name {
    text-align: center;
    margin-bottom: 2px;
    margin-top: 0;
}

.Date {
    text-align: center;
    font-size: 14px;
    margin-top: 0;
}

.ButtonDiv {
    display: flex;
    justify-content: space-evenly;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.Button {
    background-color:white;
    color: #C8747A;
    border: 1px solid #C8747A;
    border-radius: 10%;
    cursor: pointer;
    height: 35px;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.ButtonText {
    padding-left: 5px;
    padding-right: 3px;
}

.Icon {
    align-self: start;
    font-size: 14px;
    padding-top: 2px;
    padding-right: 3px;
}

.Content {
    font-size: 15px;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .Title {
        padding-top: 45px;
    }

    .ProjectItems {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .ButtonDiv {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .LearnMoreText {
        max-width: 600px;
    }
}

/*large devices (Ex. large tablets, small laptops)*/
@media (min-width: 991px) {
    .LearnMoreText {
        max-width: 850px;
    }
}