.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 75%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 30px 16px;
    top: 10%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    max-height: 85%;
    overflow-y: auto;
}

.CloseButton {
    display: block;
    width: 100px;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color: #C8747A;
    font-size: 20px;
    border: 1px solid #C8747A;
    border-radius: 5px;
    cursor: pointer;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .Modal {
        top: 15%;
    }
}

/*large devices (Ex. large tablets, small laptops)*/
@media (min-width: 991px) {
    .Modal {
        max-width: 600px;
        max-height: 100%;
    }
}
