.FeaturedSkills {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
}

.Title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #525252;
    text-decoration: underline;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 30px;
}

.LogosRow1 {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
}

.LogosRow2 {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.Logo {
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
}

.LearnMoreText {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 16px;
    color: #95585C;
    text-decoration: underline;
    padding: 30px 0px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
}

.RightArrowIcon {
    vertical-align: bottom;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .FeaturedSkills {
        max-width: 600px;
    }

    .Title {
        padding-top: 45px;
    }

    .Logo {
        height: 60px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .LearnMoreText {
        max-width: 600px;
        padding-bottom: 45px;
    }
}

/*large devices (Ex. large tablets, small laptops)*/
@media (min-width: 991px) {
    .LearnMoreText {
        max-width: 850px;
    }
}