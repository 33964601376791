.ProjectItem {
    position: relative;
    margin-top: 30px;
    border: 1px solid #525252;
    height: 230px;
    width: 270px;
    border-radius: 20px;
}

.ProjectItem:hover {
    border: 3px solid #C8747A;
    cursor: pointer;
}

.Cover {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: 0.9;
}

.NameDiv {
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 240, 240, 0.8);
    width: 100%;
    height: 20%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.Name {
    margin: 0;
    padding-left: 10px;
    padding-top: 5px;
    color: #C8747A;
    font-size: 25px;
    font-family: 'Anton', sans-serif;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .ProjectItem {
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
    }
}