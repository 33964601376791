.HomeSummary {
    text-align: center;
    background-color: rgba(200, 116, 122, 0.1);
    background-image: linear-gradient(rgba(200, 116, 122, 0.1), white, rgba(200, 116, 122, 0.1));
    height: 100%;
    margin-top: 56px;
    padding-right: 15px;
    padding-left: 15px;
}

.IntroQuote {
    font-size: 30px;
    font-style: italic;
    font-family: 'Source Sans Pro', sans-serif;
    color: #525252;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 30px;
}

.IntroSentences {
    font-size: 19px;
    font-family: 'Open Sans Condensed', sans-serif;
    color: #525252;
    margin: 0;
    padding: 5px 0px;
}

.MyInfo {
    background-color: white;
    border-radius: 20px;
    margin: 10px auto;
    text-align: left;
    max-width: 400px;
}

.MyInfoIntro {
    font-size: 16px;
    padding-left: 20px;
    padding-top: 10px;
    margin: 0;
}

.MyInfoLooking {
    font-size: 16px;
    color: #C8747A;
    padding-left: 20px;
    margin: 0;
}

.MyInfoEmail {
    font-size: 13px;
    padding-left: 20px;
    padding-top: 0px;
    margin: 0;
}

.References {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 0px;
}

.Logos {
    height: 36px;
    padding-left: 8px;
}

.ReferenceText {
    font-size: 12px;
    margin: 0;
}

.LinkIcons {
    font-size: 9px;
}

.LearnMoreText {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 16px;
    color: #95585C;
    text-decoration: underline;
    padding-top: 20px;
    padding-bottom: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
}

.RightArrowIcon {
    vertical-align: bottom;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .IntroQuote {
        font-size: 35px;
        padding: 45px 20px 10px 20px;
    }
    
    .IntroSentences {
        font-size: 24px;
        padding: 5px 15px;
    }

    .MyInfo {
        margin-top: 35px;
        max-width: 500px;
    }

    .MyInfoIntro {
        font-size: 18px;
        padding-left: 25px;
    }

    .MyInfoLooking {
        font-size: 18px;
        padding-left: 25px;
    }
    
    .MyInfoEmail {
        font-size: 15px;
        padding-left: 25px;
    }

    .Logos {
        height: 40px;
        padding-left: 10px;
    }
    
    .ReferenceText {
        font-size: 14px;
    }

    .LinkIcons {
        font-size: 10px;
    }

    .LearnMoreText {
        max-width: 600px;
        padding-bottom: 45px;
    }
}

/*large devices (Ex. large tablets, small laptops)*/
@media (min-width: 991px) {
    .IntroQuote {
        font-size: 38px;
    }
    
    .IntroSentences {
        font-size: 27px;
    }

    .MyInfo {
        max-width: 500px;
    }

    .LearnMoreText {
        max-width: 800px;
    }
}