.EducationItem {
    border-radius: 10px;
    background-color: rgba(200, 116, 122, 0.1);
    max-height: 100%;
    margin: 30px 20px 0px 20px;
    font-family: 'Roboto', sans-serif;
}

.EducationDiv {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    padding: 15px 15px;
}

.LogoDiv {
    position: relative;
    width: 75px;
    height: 75px;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    margin-right: 15px;
}

.Logo {
    position: absolute;
    max-height: 75%;
    max-width: 75%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.Header {
    width: 70%;
    height: 80%;
    color: #525252;
}

.Name {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
}

.Course {
    margin-bottom: 0;
    margin-top: 5px;
    font-weight: bold;
    font-size: 14px;
}

.Date {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 14px;
}

.DetailsDiv {
    border-top: 1px dotted #95585C;
    height: 100%;
    padding: 5px 5px;
    cursor: pointer;
}

.DetailsToggle {
    color: #95585C;
    text-align: right;
    margin: 0px 5px;
    padding-bottom: 5px;
}

.ArrowIcon {
    vertical-align: bottom;
    padding-bottom: 1px;
}

.DetailsContent {
    color: #525252;
    font-size: 15px;
    padding: 5px 15px;
}