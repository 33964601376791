.TopNavigation {
    background-color: #95585C;
    height: 56px;
    position: fixed;
    top: 0;
    width: 100vw;
    transition: top 0.3s;
    z-index: 99;
}

.WebsiteLogo {
    height: 40px;
    position: absolute;
    color: white;
    left: 10px;
    top: 9px;
}

.MenuIcon {
    position: absolute;
    color: white;
    font-size: 36px;
    border: 1px solid white;
    border-radius: 5px;
    right: 15px;
    top: 9px;
}

.NavigationItems {
    display: none;   
}

/*large devices (Ex. large tablets, small laptops)*/
@media (min-width: 991px) {
    .MenuIcon {
        display: none;
    }

    .NavigationItems {
        display: block;
    }
}