.NavigationItems {
    display: flex;
    flex-flow: column;
    padding-left: 0px;
    padding-right: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
}



/*large devices (Ex. large tablets, small laptops)*/
@media (min-width: 991px) {
    .NavigationItems {
        flex-flow: row;
        justify-content: flex-end;
        flex-wrap: nowrap;
        padding-left: 0px;
        padding-right: 10px;
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 0;
        text-align: right;
    }
}