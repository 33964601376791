.Intro {
    height: 100%;
    margin-top: 86px;
    margin-bottom: 30px;
}

.PicInfo {
    display: block;
}

.Pic {
    display: block;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.InfoSection {
    display: block;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
}

.Info {
    font-family: 'Roboto', sans-serif;
    color: #525252;
}

.Anchors {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

.AnchorLink {
    color: #C8747A;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
}

/*medium screens (Ex. tablets)*/
@media (min-width: 768px) {
    .Intro {
        display: block;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 101px;
        margin-bottom: 45px;
    }

    .PicInfo {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 300px;
    }

    .Pic {
        margin-left: 0;
        margin-right: 30px;
        margin-bottom: 0;
        height: 100%;
        width: auto;
    }

    .Info {
        margin: 0;
    }
    
    .InfoSection {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 50vw;
    }

    .Anchors {
        display: flex;
        justify-content: space-evenly;
        padding-top: 45px;
    }

    .AnchorLink {
        font-size: 18px;
    }
}