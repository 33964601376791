.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 200; /*above backdrop*/
    background-color: white;
    transition: transform 0.3s ease-out; /*react to changes of transform propertt & animate 300ms with ease out timing*/
}

.NavItems {
    margin-top: 100px;
}

.Open {
    transform: translateX(0); /*shift sidedrawer to position*/
}

.Close {
    transform: translateX(200%); /*slide to left off screen*/
}

.CloseIcon {
    position: absolute;
    color: #525252;
    font-size: 43px;
    border: 1px solid #525252;
    border-radius: 5px;
    left: 10px;
    top: 20px;
}

/*large devices (Ex. large tablets, small laptops)*/
@media (min-width: 991px) {
    .SideDrawer {
        display: none;
    }
}